import { Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { defaultLocale } from '~/app/localization';
import isLocaleSupported from '~/utils/localization/isLocaleSupported';
import { LanguageLocaleInterface } from '~/utils/localization';

@Module({
  name: 'LanguageModule',
  stateFactory: true,
  namespaced: true,
})
export default class LanguageModule extends AbstractModule {
  public initialLanguage: string = defaultLocale;
  public preferredLanguage: string = defaultLocale;

  public languageOffered: boolean = false;

  public locale: LanguageLocaleInterface | null = null;

  @Mutation
  public setLanguage(language: string) {
    if (isLocaleSupported(language)) {
      this.initialLanguage = language;
    }
  }

  @Mutation
  public setPreferredLanguage(language: string) {
    if (isLocaleSupported(language)) {
      this.preferredLanguage = language;
    }
  }

  @Mutation
  public setLanguageOffered(state: boolean) {
    this.languageOffered = state;
  }

  @Mutation
  public setLocale(locale: LanguageLocaleInterface | null) {
    this.locale = locale;
  }
}
