import '~/app/core/publicPath';
import '~/app/core/classComponent';
import '~/styles/main.scss';

import Vue from 'vue';
// Vuetify must be imported from node modules directly
// @ts-ignore
import Vuetify from '~/../node_modules/vuetify/lib';
import { getModule } from 'vuex-module-decorators';
import { sync } from 'vuex-router-sync';

import { APIClient } from '~/app/core/apiClient';
import App from '~/app/App';
import { createRouter, createStore } from '~/app/core';
import { createI18n, supportedLocales } from '~/app/localization';
import { modules } from '~/app/core/store/modules';
import AbstractModule from '~/app/core/store/modules/AbstractModule';
import LanguageModule from '~/app/core/store/modules/LanguageModule';
import VuetifyConfig from '~/app/core/vuetify';
import bestFitLanguage from '~/utils/bestFitLanguage';
import RouterModule from './core/store/modules/RouterModule';
import ServerTimingModule from './core/store/modules/LoggerModule';

const mergeMountedStrategy = Vue.config.optionMergeStrategies.mounted;
/**
 * When these are fixed, we can use optionMergeStrategies to push the mounted hook from the mixin to the end
 * @link https://github.com/vuejs/vue/issues/9623
 * @link https://stackoverflow.com/questions/52988704/vue-js-scoping-custom-option-merge-strategies-instead-going-global
 */
Vue.config.optionMergeStrategies.mounted = (
  toVal: any,
  fromVal: any,
  vm: any
) => {
  if (vm && vm.mountedBeforeMixins) {
    if (typeof fromVal === 'function') {
      fromVal = [fromVal];
    }
    if (typeof toVal === 'function') {
      toVal = [toVal];
    }
    return [...fromVal, ...toVal];
  }
  return mergeMountedStrategy(toVal, fromVal, vm);
};

Vue.config.async = true;
Vue.config.performance = true;

declare module 'vue/types/vue' {
  export interface Vue {
    $api: APIClient;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    apiClient?: APIClient;
  }
}

Vue.mixin({
  beforeCreate() {
    if (this.$options.apiClient) {
      this.$api = this.$options.apiClient;
    } else if (this.$options.parent && this.$options.parent.$api) {
      this.$api = this.$options.parent.$api;
    }
  },
});

Vue.directive('blur', {
  inserted: (el) => {
    el.onfocus = () => el.blur();
  },
});

// export a factory function for creating fresh app, router and store
// instances
export function createApp(context: any = null) {
  const store = createStore();
  const loggerModule = getModule(ServerTimingModule, store);
  if (context && context.addServerTiming) {
    loggerModule.setTimingSetter(context.addServerTiming);
  }
  const languageStore = getModule(LanguageModule, store);
  if (context) {
    if (context.isCrawler) {
      getModule(RouterModule, store).setIsCrawler(true);
    }
    if (context.isMobile) {
      getModule(RouterModule, store).setIsMobile(true);
    }
    if (context.acceptedLanguages) {
      const fullySupportedLocales = supportedLocales
        .filter((item) => item.fullySupported)
        .map((item) => item.slug);
      languageStore.setPreferredLanguage(
        bestFitLanguage(
          fullySupportedLocales,
          context.acceptedLanguages,
          languageStore.initialLanguage
        )
      );
    }
    context.languageIso = languageStore.initialLanguage;
  }

  const router = createRouter(store);
  const i18n = createI18n();

  const vuetify = new Vuetify({
    lang: {
      t: (key: string, ...params: (string | number)[]) =>
        i18n.t(key, params).toString(),
      current: languageStore.initialLanguage,
    },
    ...VuetifyConfig,
  });

  sync(store, router);

  router.beforeEach((to, from, next) => {
    let locale = languageStore.initialLanguage;
    for (const matched of to.matched) {
      if (matched.meta.locale) {
        locale = matched.meta.locale;
        break;
      }
    }
    // Set locale from the router
    i18n.locale = locale;

    if (context) {
      // Add locale during SSR render
      context.languageIso = locale;
    }

    // It might be necessary to change vuetify locale here, but i18n should take over
    next();
  });

  const apiClient = new APIClient(context?.log);

  const app = new Vue({
    apiClient,
    router,
    store,
    vuetify,
    i18n,
    beforeCreate: () => {
      // Set apollo to the store modules
      for (const moduleName in modules) {
        if (!modules.hasOwnProperty(moduleName)) {
          continue;
        }

        const module = (getModule(
          modules[moduleName] as any,
          store
        ) as unknown) as AbstractModule;
        module.$api = apiClient;
      }
    },
    // the root instance simply renders the App component.
    render: (h) => {
      const start = Date.now();
      const render = h(App);
      if (context && context.addServerTiming) {
        context.addServerTiming('vueRender;dur=' + (Date.now() - start));
      }
      return render;
    },
  });
  return { app, router, store };
}
