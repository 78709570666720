import { Component, Watch } from 'vue-property-decorator';
import { Footer, Header } from '~/components/templates/common';
import CookieBar from '~/components/templates/common/CookieBar';
import CTAPopUp from '~/components/templates/common/CTAPopUp';
import NewsletterPopUp from '~/components/templates/common/NewsletterPopUp';
import { getModule } from 'vuex-module-decorators';
import LanguageModule from './core/store/modules/LanguageModule';
import {
  getCurrentLocale,
  LanguageLocaleInterface,
} from '~/utils/localization';
import { CTATimeout } from '~/utils/newsletter/config';
import isCTAShowable from '~/utils/newsletter/isCTAShowable';
import setCTAShown from '~/utils/newsletter/setCTAShown';
import { getLocaleFromRouter } from './core/router';
import UserModule from './core/store/modules/UserModule';
import VisitMetricsModule from './core/store/modules/VisitMetricsModule';
import RouterModule from './core/store/modules/RouterModule';
import { OneOfThePages } from '~/utils/views';

import fonts from '~/assets/fonts/fonts.css';

import style from './campaign.scss';
import { VueComponentMixin } from '~/utils/vue-component';
import HeadManagement from '~/mixins/HeadManagement';
import Redirect from '~/utils/http/Redirect';
import LanguagePopup from '~/components/templates/common/LanguagePopup';
import CookieSettingsModule from './core/store/modules/CookieSettingsModule';

@Component({ style })
export default class App extends VueComponentMixin(HeadManagement) {
  public fontTags(): string {
    return `<link rel="stylesheet"
    href="${fonts}"
    media="print" onload="this.media='all'" />`;
  }

  protected get temporaryRedirect(): string | null {
    if (
      this.$router.currentRoute.redirectedFrom &&
      typeof this.$router.currentRoute.query.temporaryRedirect === 'string'
    ) {
      return this.$router.currentRoute.query.temporaryRedirect;
    }

    return null;
  }

  protected ctaActive: boolean = false;
  protected newsletterActive: boolean = false;
  protected showCTATimeout: ReturnType<typeof setTimeout> | null = null;
  protected pathChangeNr: number | null = null;

  protected get languageStore(): LanguageModule {
    return getModule(LanguageModule, this.$store);
  }

  protected get cookieBarVisible(): boolean {
    return getModule(CookieSettingsModule, this.$store).cookieBarVisible;
  }

  protected get userModule(): UserModule {
    return getModule(UserModule, this.$store);
  }

  protected get visitMetricsModule(): VisitMetricsModule {
    return getModule(VisitMetricsModule, this.$store);
  }

  protected get currentLocale(): LanguageLocaleInterface | null {
    return this.languageStore.locale;
  }

  protected get routerModule(): RouterModule {
    return getModule(RouterModule, this.$store);
  }

  protected get resource(): OneOfThePages | null {
    return this.routerModule.resource;
  }

  protected get languagePopupActive() {
    return (
      this.languageStore.initialLanguage !==
        this.languageStore.preferredLanguage &&
      !this.languageStore.languageOffered &&
      this.$router.currentRoute.path === '/'
    );
  }

  public created() {
    this.updateLocale();
  }

  public mounted() {
    this.pathChangeNr = this.currentLocale?.newsletterCtaPathChanges || 1;
    this.countPathChanges();
    if (typeof document !== 'undefined') {
      document.addEventListener('copy', this.copyToClipboard);
    }
    this.visitMetricsModule.recordVisit();
  }

  public render() {
    if (this.temporaryRedirect) {
      return <Redirect location={this.temporaryRedirect} statusCode={307} />;
    }

    let isCampaign = false;
    const classes = [];
    if (this.currentLocale) {
      classes.push(`lang--${this.currentLocale.slug.toLowerCase()}`);
    }
    if (
      this.resource?.nodeAliasPath.includes('/Campaigns/stay-in-prague') &&
      !this.routerModule.loading
    ) {
      isCampaign = true;
      classes.push('vcr-campaign-page');
    }

    return (
      <v-app id='app' class={classes.join(' ')} key={this.userModule.token}>
        <Header isCampaign={isCampaign} />
        <v-main>
          <router-view />
        </v-main>
        <Footer />
        <CookieBar
          visible={this.cookieBarVisible && !this.languagePopupActive}
        />
        <CTAPopUp
          active={this.ctaActive}
          onClose={this.closeCTA}
          onConfirm={() => {
            this.openNewsletter();
            this.closeCTA();
          }}
        />
        <LanguagePopup active={this.languagePopupActive} />
        <NewsletterPopUp
          active={this.newsletterActive}
          onClose={this.closeNewsletter}
        />
      </v-app>
    );
  }

  public beforeDestroy() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('copy', this.copyToClipboard);
    }
  }

  @Watch('$route.path')
  protected countPathChanges() {
    if (
      !this.currentLocale ||
      this.currentLocale.slug !== getLocaleFromRouter(this.$router)
    ) {
      this.updateLocale();
      this.pathChangeNr = this.currentLocale?.newsletterCtaPathChanges || 1;
    }
    if (!this.currentLocale || !isCTAShowable(this.currentLocale)) {
      return;
    }
    if (this.pathChangeNr === 0) {
      if (!this.showCTATimeout) {
        this.showCTATimeout = setTimeout(() => {
          if (
            this.currentLocale &&
            isCTAShowable(this.currentLocale) &&
            !this.ctaActive
          ) {
            this.ctaActive = true;
          }
          if (this.showCTATimeout) {
            clearTimeout(this.showCTATimeout);
          }
          this.showCTATimeout = null;
        }, CTATimeout * 1000);
      }
    }
    this.pathChangeNr = this.pathChangeNr ? --this.pathChangeNr : null;
  }

  protected updateLocale() {
    this.languageStore.setLocale(
      getCurrentLocale(getLocaleFromRouter(this.$router))
    );
  }

  protected closeCTA() {
    this.ctaActive = false;
    if (this.currentLocale) {
      setCTAShown(this.currentLocale);
    }
  }

  protected openNewsletter() {
    this.newsletterActive = true;
  }

  protected closeNewsletter() {
    this.newsletterActive = false;
  }

  protected copyToClipboard(event: ClipboardEvent) {
    if (typeof document !== 'undefined') {
      const selection = document.getSelection()?.toString();
      if (selection && selection.split(' ').length > 10) {
        event.clipboardData?.setData(
          'text/plain',
          `${selection}\n${this.$t('app.common.source')}: ${document.location}`
        );
        event.preventDefault();
      }
    }
  }
}
